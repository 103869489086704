#pq-panel {
  border-top: 4px solid #2859b6;
  font-family: Segoe UI;
  background: #ffffff;
  padding-bottom: 40px;
  box-shadow: 0px 2px 10px lightgrey;
  font-size: 15px;
  line-height: 20px;
  padding-left: 0px;
  padding-right: 0px;

}

.install-pq {
  background-color: #2859b6;
  color:white;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding: 10px 0px;
  margin-bottom: 0px;
}
.install-pq span {
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #2859b6;
  color: #2859b6;
  box-sizing: border-box;
  margin-right: 10px;

  display: inline-block;
    width: 32px;
    height: 32px;
    padding-top: 3px;
    
}
#pq-panel h2 {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2859b6;
  padding-top: 30px;
}

#lst-pq {
  list-style-image: url("../../../img/sso/star-bullet.svg");
  padding-left:20px;
}

.txt-bold {
  font-weight: bold;
}
.txt-italic {
  color: #333333;
  font-style: italic;
font-weight: normal;
}
.pt-2-p
{
    padding-top: 2%;
}
.disabled, .disabled span
{
    color:grey;
    border-color:grey;
    background-color: inherit;
    
}
.div-install-pq{
    border-right: 1px solid lightgrey
}
.wzr-header
{
    border-bottom: 1px solid lightgrey;
}
.wzr-content .col-6 * {
    padding: 5px;
}
.checked
{
    background-color: #2859b6 !important;
    border-color:#2859b6 !important;
}
.loading-container-login
{
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  z-index: 10000;
  top: 0px;
  left: 0px;
  padding-top: 35%;
  padding-bottom: 460px;
}
.n-mp
{
  margin: 0px;
  padding: 0px;
}