.admin-consent-main {
    width: 100%;
    padding: 65px 125px 65px 125px;
    .admin-consent-description {
        padding-right: 50px;
        &>p {
            font-size: 20px;
        }

        .title {
            text-align: left;
            font-weight: 600;
            font-size: 55px;
            font-family: 'Sagoe UI', sans-serif;
            letter-spacing: 0px;
            color: #2859B6;
            opacity: 1;
            padding-bottom: 30px;
        }

        ul {
            list-style: none;

            li {
                font-size: 19px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .list-number {
                    margin-right: 8px;
                    text-align: left;
                    font-weight: 600;
                    font-size: 40px;
                    font-family: 'Sagoe UI', sans-serif;
                    letter-spacing: 0px;
                    color: #2859B6;
                    opacity: 1;
                    display: flex;
                    align-self: flex-start;
                }

                img{
                    width: 19px;
                    margin-right: 5px;
                }
            }

        }

        .admin-consent-warning {
            border: 10px solid #FF7D7E;
            background-color: #FFFFFF;
            padding: 20px 40px 10px 40px;
            margin-bottom: 30px;
            position: relative;
            p {
                font-size: 18px;
                margin-bottom: 0;
                &.consent-warning-text {
                    font-size: 20px;
                    color: #f14445;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
            &::after{
                content: "!";
                position: absolute;
                top: -63px;
                left: 5px;
                font-size: 100px;
                color: #f14445;
                font-weight: bold;
                font-family: 'Sagoe UI', sans-serif;
            }
        }

        .admin-consent-no-consent {
            border: 10px solid #2859B6;
            background-color: #FFFFFF;
            padding: 20px 40px 10px 40px;

            p {
                font-size: 18px;
                margin-bottom: 0;
            }
        }

    }
    .consent-options {
        img{
            width: 467px;
            margin-bottom: 60px;
        }
        button{
            color: #FFFFFF;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 8px;
            padding: 15px;
            width: 467px;
            text-align: center;
            font-size: 18px;
            font-family: 'Sagoe UI', sans-serif;
            letter-spacing: 0.2px;
            &.btn-accept-consent {
                background: #21CE99 0% 0% no-repeat padding-box;
                font-weight: bold;
                margin-bottom: 25px;
            }
        
            &.btn-reject-consent {
                background: #2859B6 0% 0% no-repeat padding-box;
                margin-bottom: 25px;
            }
            &.btn-go-demo {
                background: #753ba1 0% 0% no-repeat padding-box;
            }
        }
    
    }
}

