.colorZone
{
    width: fit-content;
}
.color-section
{
margin-right: 20px;
}
.logo-section {
    padding-right: 20px;
}
.mainSection{
    display: flex;
    flex-wrap: wrap;
}
.default-color-item{
    width: 50px;
    height: 50px;
    margin-right: 20px;
    cursor: pointer;
    margin-bottom: 20px;
}
.color-picker-zone{
    
}
.default-colors {
    flex-wrap: wrap;
    display: flex;
    max-width: 300px;
}
.color-picker-buttons {
    display: flex;
    justify-content: space-between;
}