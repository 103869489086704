html {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./img/playquiz-background.png");
  background-size: cover;
}

#root,
.AppLayout,
.pageContainer {
  width: 100%;
  padding-bottom: 50px;
}

.mainContent {
  padding-top: 50px;
}


@media (max-width: 700px) {
  .mainContent {
    padding-top: 30px;
  }
}

.mainContent>div {
  height: 100%;
}

.mainContent>.contenedor {
  height: initial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.footerContainer {
  position: fixed;
  height: 80px;
  width: 100%;
  background-color: #2859B6;
  color: white;
  padding: 30px;
  bottom: 0px;
  z-index: 1;
}

.imageFooter {
  position: absolute;
  top: calc(100% - 148px);
  left: calc(100% - 205px);
}

a {
  color: white;
}

a:hover {
  color: white;
  font-weight: 600;
}