

#fileUpload
{
    display: none;
}


.imageZone{
    width: fit-content;
    img{
        min-width: 100px;
        min-height: 100px;
        max-width: 300px;
        max-height: 300px;
        box-shadow: 0px 3px 6px #00000029;
    }
}
.iconUpload{
    font-size: 25px;
    cursor: pointer;
}
.colorPalette
{
    box-shadow: 0px 3px 6px #00000029;
    width: 100px;
    height: 100px;
}

.selectionZone{
    display: flex;
    justify-content: space-between;
}