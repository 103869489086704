.categoriesContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;


}

.categoriesItem {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: initial;
    align-items: auto;
    align-content: center;
    width: 25%;
    padding: 10px;
    box-sizing: border-box;
}

.categoriesItem>div {
    margin: 0 10px;
}