mgt-people-picker {}

.aSinDec {
    color: grey;
    padding-top: 20px;
}

.aSinDec:hover {
    cursor: pointer;
}

.oculto {
    display: none;

    .botonCerrar {
        text-align: right;

        >.btn {
            padding: 3px;
            font-size: 20px;
            color: #707070;
        }
    }
}

.divInputUser {
    padding: 0;
    height: 35px;
    margin-top: 15px;
    width: 100%;
    display: flex;

}

.inputUser {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    width: 100%;
}

.divDomain {
    padding-top: 5px;
    padding-left: 10px;
}

div>.peoplePicker {
    >.row>.col-9 {
        padding: 0 !important;
    }

    .row {
        margin: 10px 0 20px;
    }

    div>.row {
        margin: 6px 0;
    }
}

.gris {
    color: #707070;

    p {
        text-decoration: underline;
        cursor: pointer;
    }
}

.errorZone {
    background-color: lightcoral;
    color: white;
    padding: 0px 10px;
    font-style: italic;
}