*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#root>.mainContent>.contenedor {
  padding-top: 0 !important;
}

.mainContent {
  margin-top: 60px;
  height: calc(100% - 60px);
}

@media (max-width: 700px) {
  .mainContent {
    margin-top: 0px;
  }
}

.contenedor {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedor .container-fluid {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >.row {
    margin: 0 auto;
    height: 100%;
    max-width: 1512px;
    min-height: 578px;
    justify-content: center;

    >.col {
      -webkit-margin-before: initial;
      -moz-margin-before: initial;
      -o-margin-before: initial;
      display: flex;
      flex-direction: column;
      min-width: 320px;
      max-width: 726px;
      min-height: 610px;
      width: 80vw;
    }
  }
}

.perfil,
.empezarReto {
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.29);
  opacity: 1;
  max-width: 726px;

  @media (max-width: 1199px) {
    height: auto;
  }
}

.empezarReto {
  position: relative;
  margin: 30px 0;

  .row {
    height: 100%;
  }

  .col>div {
    font: 16px/18px Segoe UI, sans-serif;
    letter-spacing: 0;
    text-align: center;
    color: grey;
    line-height: 1.4em;

    h4 {
      font-weight: bold;
      margin-bottom: 0;
      font-size: 16px;
    }

    p {
      font-size: 16px;
    }
  }

  .col-3 {
    position: absolute;
    top: 30px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: 500px) {
      top: 6px;
      right: 10px;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    img {
      margin-top: 5px;
      height: 30px;
    }
  }

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    >.btn {
      font-size: 24px;
      margin-bottom: 3%;
    }

    >img {
      height: 65%;
    }
  }

  img {
    height: 30px;
  }

  h4 {
    margin-left: 7px;
    font-size: 28px;
  }
}

.header-menu {
  mgt-person {
    --avatar-size-s: 50px;
  }
}

.perfil {
  margin-bottom: 2.5vh;
  -webkit-margin-after: initial;
  -moz-margin-after: initial;
  -o-margin-after: initial;

  .row {
    height: 100%;
    margin: 0 20px;

    @media (max-width: 1199px) {
      margin: 0;
    }

    @media (max-width: 991px) {
      margin: 0 10px;
    }

    @media (max-width: 400px) {
      margin: 0;
    }

    mgt-person {
      --avatar-size-s: 135px;

      @media (max-width: 1199px) {
        --avatar-size-s: 90px;
      }
    }
  }

  .progress-bar-success {
    background-color: var(--main-color-greenSuccess) !important;
  }

  .progress-bar-ko {
    background-color: var(--main-color-redError) !important;
  }

  .container-profile-card {
    position: relative;
    overflow: hidden;

    .shareRRSS {
      position: absolute;
      top: 6%;
      right: 4%;
      z-index: 2;

      @media (max-width: 600px) {
        right: 0;
      }

      .iconRRSS {
        cursor: pointer;
      }
    }

    .profile-card {
      padding: 40px 0;



      .user-info-points {
        align-items: center;
        justify-content: center;
      }

      .user-image {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (max-width: 1199px) {
          align-items: flex-start;

          >div>div>div {
            height: 67% !important;
          }
        }

        .circular {
          height: 160px;
          width: 160px;

          >path.CircularProgressbar-path {
            stroke: var(--main-color-greenSuccess);
          }

          &.aura-user-gold {
            >path.CircularProgressbar-path {
              stroke: var(--main-color-gold);
            }
          }

          &.aura-user-silver {
            >path.CircularProgressbar-path {
              stroke: var(--main-color-silver);
            }
          }

          &.aura-user-bronze {
            path.CircularProgressbar-path {
              stroke: var(--main-color-bronze);
            }
          }

          &.aura-user-Platinium {
            >path.CircularProgressbar-path {
              stroke: var(--main-color-platinum);
            }
          }



          @media (max-width: 1199px) {
            height: 132px;
            width: 110px;
          }
        }

        .avatar {
          height: 100px;
          width: 100px;
        }

        .image-avatar {
          border-radius: 50%;
        }

        .level {
          padding-top: 5px;
          font-family: Segoe UI, sans-serif;
          font-weight: 700;
          font-size: 18px;

          @media (max-width: 1199px) {
            font-size: 20px;
          }

          color: #707070;
        }

        .user-position {
          font-size: 18px;

          @media (max-width: 1199px) {
            font-size: 15px;
          }
        }
      }
    }

    .profile-card .user-info {
      display: flex;
      align-items: flex-start;
      flex-direction: column !important;
      padding-top: 25px;

      @media (max-width: 575px) {
        padding-top: 0;
      }

      .d-flex {
        justify-content: space-around;
        flex-direction: column;

        .root {
          line-height: 0;
        }
      }

      .user-name {
        font-family: Segoe UI, sans-serif;
        font-weight: bold;
        font-size: 32px;

        @media (max-width: 1199px) {
          font-size: 24px;
        }

        color: #242424;
      }

      .user-points {
        color: #707070;
        font-size: 26px;
        font-family: Segoe UI, sans-serif;
        padding-top: 15px;
        display: flex;
        align-items: center;

        @media (max-width: 575px) {
          font-size: 20px;
          padding-top: 0;
        }

        .puntos {
          margin-left: 10px;
          display: inline-flex;
          text-transform: lowercase;
          line-height: 24px;
          font-weight: bold;
        }

        img {
          height: 30px;
        }
      }

      .text {
        font-size: 22px;

        @media (max-width: 1199px) {
          font-size: 16px;
        }

        padding: 14px 0 10px 0;
      }

      .daily {
        width: 300px;

        @media (max-width: 1199px) {
          width: 195px;
        }

        display: flex;
        justify-content: space-between;
        text-align: center;
        position: relative;

        .done {
          background-color: var(--main-color-greenSuccess);
        }

        .failed {
          background-color: var(--main-color-redError);
        }

        .progress {
          width: 285px;

          @media (max-width: 1199px) {
            width: 190px;
          }

          margin-left: 2px;
          height: 10%;
          position: absolute;
          align-self: center;
          z-index: 0;
          border-radius: 0;
        }

        div {
          font-size: 28px;
          width: 40px;
          border-radius: 50%;
          background-color: #b8b8b8;
          color: white;
          z-index: 1;

          @media (max-width: 1199px) {
            font-size: 18px;
            width: 26px;
          }
        }
      }
    }

    .profile-card-hidden {
      padding: 40px 0;
      position: absolute;
      top: 0;
      z-index: -1;

      .user-info {
        display: flex;
        align-items: flex-start;
        flex-direction: column !important;
        padding-top: 25px;

        .d-flex {
          justify-content: space-around;
          flex-direction: column;
        }

        .user-name {
          font-family: Segoe UI, sans-serif;
          font-weight: bold;
          font-size: 32px;
          color: #242424;
        }

        .user-points {
          color: #707070;
          font-size: 26px;
          font-family: Segoe UI, sans-serif;
          padding-top: 10px;

          .puntos {
            margin-left: 10px;
          }

          img {
            height: 30px;
            margin-bottom: 5px;
          }
        }

        .text {
          font-size: 22px;
          padding-top: 30px;
        }

        .daily {
          width: 300px;
          display: flex;
          justify-content: space-between;
          text-align: center;
          position: relative;

          .done {
            background-color: var(--main-color-greenSuccess);
          }

          .failed {
            background-color: var(--main-color-redError);
          }

          .progress {
            width: 285px;
            margin-left: 2px;
            height: 10%;
            position: absolute;
            align-self: center;
            z-index: 0;
            border-radius: 0;
          }

          div {
            font-size: 28px;
            width: 40px;
            border-radius: 50%;
            background-color: #b8b8b8;
            color: white;
            z-index: 1;
          }
        }
      }

      .user-image {
        display: flex;
        align-items: center;
        flex-direction: column;

        .circular {
          height: 160px;
          width: 160px;
        }

        .avatar {
          height: 100px;
          width: 100px;
        }

        .image-avatar {
          border-radius: 50%;
        }

        .level {
          padding-top: 5px;
          font-family: Segoe UI, sans-serif;
          font-weight: 700;
          font-size: 24px;
          color: #707070;
        }

        .user-position {
          font-size: 18px;
        }
      }
    }
  }
}

.ranking {
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  -ms-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.29);
  opacity: 1;
  height: 600px;
  position: relative;
  top: 40px;

  .btn-ranking {
    position: relative;
    top: -40px;
    left: -15px;
    font-size: 20px;
    font-weight: bold;
    box-shadow: -4px -2px 9px -4px rgba(0, 0, 0, 0.29);

    >button {
      border: 0px;
      padding: 5px 20px 5px 20px;

      &.btn-Active {
        background: white;
        position: relative;
        z-index: 1;
        box-shadow: 2px -2px 4px -2px rgba(0, 0, 0, 0.29);
      }

      &.btn-Disanbled {
        background: #f1f1f1;
        color: #b9b8b8;
      }

      &:hover {
        filter: brightness(90%);
      }

      &:focus {
        outline: 0px;
      }
    }
  }

  hr {
    margin-top: 0;
  }

  .top5 {
    top: -40px;
    position: relative;
    height: 100%;
    padding: 20px;

    @media (max-width: 991px) {
      padding: 15px 0;
    }

    mgt-person {
      --avatar-size-s: 40px;
    }

    .invite {
      display: flex;
      justify-content: flex-end;
    }

    .invitar {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-around;
      height: 80%;

      p {
        color: grey;
        width: 60%;
        text-align: center;
      }
    }

    .fueraRanking {
      font-size: 20px;
      font-weight: 700;
      color: #707070;

      @media (max-width: 400px) {
        font-size: 16px;
      }
    }

    .user-row:nth-of-type(2) i.fa-medal {
      color: #ffdf47;
    }

    .user-row:nth-of-type(3) i.fa-medal {
      color: #b8b8b8;
    }

    .user-row:nth-of-type(4) i.fa-medal {
      color: #d2a05a;
    }

    .user-row {
      height: 9.5%;

      i.fa-medal {
        font-size: 25px;
      }

      .row {
        display: flex;
        align-items: center;
        margin: 0 auto;
        height: 100%;
        max-width: 600px;
        width: 100%;

        .col-1 {
          padding: 0;
        }

        .style-scope {
          display: flex;
          border-radius: 50%;
          width: 100%;

        }

        .user-image {
          img {
            min-width: 40px;
            max-height: 40px;
          }
        }

        .user-name {
          font-family: Segoe UI, sans-serif;
          font-weight: 400;
          font-size: 1.5vw;

          @media (max-width: 991px) {
            font-size: 2.5vw;
          }

          @media (min-width: 1200px) {
            font-size: 20px;
          }

          @media (max-width: 400px) {
            max-width: 58%;
            flex: 0 0 58%;
            font-size: 12px;
          }

          color: #707070;
          padding: 0;
          padding-left: 40px;
        }

        .ico-puntos {
          @media (max-width: 400px) {
            display: none;
          }

          text-align: right;
        }

        .puntos {
          text-align: right;
          font-size: 1.3vw;

          @media (max-width: 991px) {
            font-size: 2.5vw;
          }

          @media (min-width: 1200px) {
            font-size: 20px;
          }

          @media (max-width: 400px) {
            font-size: 12px;
          }

          padding: 0;
        }
      }

      .avatar {
        height: 40px;
        width: 40px;
      }
    }
  }
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: none !important;
  }

  .hidden-md {
    display: block;
  }

  .visible-md {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .hidden-md {
    display: none !important;
  }

  .visible-md {
    display: block;
  }
}