:root {
    --main-color-gold: #ffdf47;
    --main-color-silver: #a17cfd;
    --main-color-bronze: #d2a05a;
    --main-color-platinum: #4ab7c9;

    --main-color-greenSuccess: #21ce99;
    --main-color-redError: #f74a70;



    --main-color-lightGray: #b8b8b8;
    --main-color-white: #fff;
    --main-color-darkGray: #707070;

    --main-color-bgColor: #f3f2f0;
    --main-color-fontColor: #333;
    --main-color-primaryColor: #aaa9a7;

}