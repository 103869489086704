/*FUENTES*/
@font-face {
    font-family: "SegoeBold";
    src: url("../fonts/segoeuib.ttf") format("truetype");
}


@font-face {
    font-family: "SegoeSemiBold";
    src: url("../fonts/seguisb.ttf") format("truetype");
}